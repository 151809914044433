class Secondarymenu extends HTMLElement {
  constructor() {
    super();
  }

  get buttonSecondaryMenu() {
    return this.querySelector('[data-trigger="toggleSecondaryMenu"]');
  }

  get secondaryMenuContent() {
    return this.querySelector('[data-trigger="toggleSecondaryMenu"] + ul');
  }

  get menuLinks() {
    return this.querySelectorAll('a');
  }

  getPathWithoutLocale(url) {
    const path = url.includes('://') ? new URL(url).pathname : url;
    return '/' + path.split('/').slice(2).filter(Boolean).join('/');
  }

  setActiveMenuItem() {
    const currentPath = this.getPathWithoutLocale(location.pathname);
    
    this.menuLinks.forEach(link => 
      this.getPathWithoutLocale(link.getAttribute('href')) === currentPath && 
      link.classList.add('--active')
    );
  }

  bind() {
    this.buttonSecondaryMenu.addEventListener("click", () => {
      this.secondaryMenuContent.classList.toggle("--open");
    });
  }

  connectedCallback() {
    this.bind();
    this.setActiveMenuItem();
  }
}

customElements.define("m-secondarymenu", Secondarymenu);